import React from 'react';
import './ImageSplit.css';
import image from '../../Assets/hackarena_1_0_yellow_3.jpg'; // Replace with your image path
import useWindowWidth from '../../Hooks/useWindowWidth';

const gap = 0.6;

const shapes: Array<number> = [53, 57, 60, 57, 54, 50, 45, 35, 20, 0];



const ImageGrid = () => {
    const windowWidth = useWindowWidth();

    return (
        <div className="image-container" >
            {shapes.map((width, index) => (
                <>
                    <div
                        key={index}
                        className="shape"
                        style={{
                            height: `calc(${100 / shapes.length}% - ${gap}rem)`,
                            width: windowWidth > 700 ? `${width}%` : "0%",
                            top: `calc(${index} * (${100 / shapes.length} % + ${gap}rem))`,
                        }}
                    >
                    </div >
                    <div style={{ width: "100%", height: `${gap}rem`, backgroundColor: "rgb(var(--background-color))" }}>

                    </div>
                </>
            ))
            }
            <div style={{ width: "100%", height: `${gap}rem`, backgroundColor: "rgb(var(--background-color))" }}>

            </div>
        </div >
    );
}

export default ImageGrid;