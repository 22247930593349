import "./HomePage.css";
import { useNavigate } from "react-router";
import useTimeToEvent from "../../Hooks/useTimeToEvent";
import text from "../../Assets/text.json";
import { eventStartDate, registrationEndDate, registrationStartDate } from "../../Constants/Dates";
import dateFormat, { DateFormat } from "../../Utils/dateFormat"
import { useEffect, useState } from "react";
import FAQComponent from "../../Components/FAQ/FAQ";
import Button from "../../Components/Button/Button";
import Page from "../../Components/Page/Page";
import { PageText } from "./types";
import getEventStatus, { EventStatus } from "../../Utils/getEventStatus";
import replacePlaceholders from "../../Utils/replacePlaceholders";
import ImageSplit from "../../Components/ImageSplit/ImageSplit";
import Sponsors from "../../Components/Sponsors/Sponsors";

interface Props { }

function HomePage(props: Props) {
  const navigate = useNavigate();
  const timeToEvent = useTimeToEvent();
  const pageText: PageText = text.home;
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const aboutText = document.getElementById("about_text");
    const nextEventText = document.getElementById("next_event_text");

    if (aboutText) {
      aboutText.innerHTML = pageText.aboutUs.description;
    }

    if (nextEventText) {
      nextEventText.innerHTML = pageText.nextEvent.description;
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMouse({ x: e.clientX, y: e.clientY });
    }

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  useEffect(() => {
    const stripesOdd = document.querySelectorAll(".stripe-odd") as NodeListOf<HTMLDivElement>;
    const stripesEven = document.querySelectorAll(".stripe-even") as NodeListOf<HTMLDivElement>;

    stripesOdd.forEach((stripe, index) => {
      stripe.style.transform = `translateX(calc(${scrollPosition / 5}px + 5rem))`;
    });

    stripesEven.forEach((stripe, index) => {
      stripe.style.transform = `translateX(-${scrollPosition / 5}px
      )`;
    });
  }, [scrollPosition]);

  return (
    <Page pageTitle={pageText.meta.title} description={pageText.meta.description} paddingTop={false} paddingBottom={false}>
      <div className="home">
        <div className="home__banner pagewidth">
          <div className="banner__text">
            <h1 className="">
              HackArena
            </h1>
            <p>Hackathon na SGGW</p>
            <span>Zbierz zespół i podejmij wyzwanie</span>
          </div>
          <ImageSplit />

        </div>
        {/* <div className="asdf">
          <div className="asdf__backdrop"></div>
        <div className="asdf__text">
          <h1 className="asdf__header">HackArena</h1>
          <span>Zbierz zespół i dołącz do wydarzenia</span>
        </div>

      </div> */}
        {/* <div id="welcome--wrapper" className="banner">

         

          <h1 className="hackarena">HackArena</h1>
          <div className="banner__header">
            <h1 className="header__white">HackArena</h1>
            <span>safsafd</span>
            <h6>a dsfk saflks afl sak fjl</h6>
          </div>
          <div className="banner__stripes">
            {
              Array.from({ length: 30 }).map((_, index) => {
                return <div key={index} className={`stripe ${index % 2 === 0 ? "stripe-even" : "stripe-odd"}`}>
                  {
                    Array.from({ length: 10 }).map((_, index) => {
                      return <h1>HackArena</h1>
                    })
                  }
                </div>
              })
            }
          </div>

        </div> */}
        {
          getEventStatus() === EventStatus.EventLive &&
          <div className="home--date home--section">

            <h3>{pageText.date.eventLive}</h3>
          </div>
        }
        {
          getEventStatus() === EventStatus.EventDone &&
          <div className="home--date home--section">
            <h3>{pageText.date.eventDone}</h3>
          </div>
        }


        {
          getEventStatus() === EventStatus.RegistrationOpen &&
          <div id='nieczekaj' className="home--dontwait pagewidth home--section">
            {/* TODO: style dont wait change id? */}
            <h2 className="header__white">{pageText.dontWait.title}</h2>
            <h4>{pageText.dontWait.description}</h4>
            <p>{replacePlaceholders(pageText.dontWait.dateReminder, dateFormat(registrationEndDate, DateFormat.DATE))}</p>
            <Button onClick={() => navigate("/rejestracja")} className="btn btn__primary-w btn__primary-w-border">{pageText.dontWait.button}</Button>
          </div>
        }
        <div id="o_nas" className="home--about pagewidth home--section">
          <h2 className="header__white">{pageText.aboutUs.title}</h2>
          <p id="about_text"></p>
        </div>
        <div className="home--about__event">
          <div id="hackarena2" className="home--about pagewidth home--section">
            <h2 className="header__black">{pageText.nextEvent.title}</h2>
            <p id="next_event_text"></p>
            <Button onClick={() => navigate("/wydarzenia/hackarena2_0")} className="btn btn__primary-b btn__primary-b-border">{pageText.nextEvent.button}</Button>
          </div>
        </div>
        {/* <div id="agenda" className="home--agenda home--section pagewidth">
        <h1>{pageText.agenda.title}</h1>
        <div className="agenda--schedule">
          {
            pageText.agenda.schedule.map(({ time, event }, index) => {
              return <div key={index} className="agenda--component">
                <p>{time}</p>
                <p>{event}</p>
              </div>
            })
          }
        </div>
      </div> */}
        <div className="pagewidth home--section">
          <Sponsors />
        </div>

        <div id="faq" className="home--faq pagewidth home--section">
          <h2 className="header__white">{pageText.faq.title}</h2>
          <FAQComponent questions={pageText.faq.questions} />
        </div>
      </div>
    </Page >
  );
}

export default HomePage;
