import React from 'react'
import './Sponsors.css'
import SponsorsData from './sponsorsData'
import ComponentText from './types'
import text from '../../Assets/text.json'

function Sponsors() {
    const componentText: ComponentText = text.sponsors;

    return (
        <div className="sponsors">
            {
                SponsorsData.sponsors.length > 0 && (
                    <div className="sponsors__section">
                        <h2 className="header__white">{componentText.titles.sponsors}</h2>
                        <div>
                            {
                                SponsorsData.sponsors.map((sponsor, index) => {
                                    return <a key={index} href={sponsor.url} target="_blank" rel="noreferrer">
                                        <img src={sponsor.logo} title={sponsor.name} alt={sponsor.name} />
                                    </a>
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                SponsorsData.partners.length > 0 && (
                    <div className="sponsors__section">
                        <h2 className="header__white">{componentText.titles.partners}</h2>
                        <div>
                            {
                                SponsorsData.partners.map((sponsor, index) => {
                                    return <a key={index} href={sponsor.url} target="_blank" rel="noreferrer">
                                        <img src={sponsor.logo} title={sponsor.name} alt={sponsor.name} style={sponsor.height ? { height: `${sponsor.height}px` } : {}} />
                                    </a>
                                })
                            }
                        </div>
                    </div>
                )

            }
            {
                SponsorsData.patronage.length > 0 && (
                    <div className="sponsors__section">
                        <h2 className="header__white">{componentText.titles.patronage}</h2>
                        <div>
                            {
                                SponsorsData.patronage.map((sponsor, index) => {
                                    return <a key={index} href={sponsor.url} target="_blank" rel="noreferrer">
                                        <img src={sponsor.logo} title={sponsor.name} alt={sponsor.name} style={sponsor.height ? { height: `${sponsor.height}px` } : {}} />
                                    </a>
                                })
                            }
                        </div>
                    </div>
                )

            }
            {
                SponsorsData.mediaPatronage.length > 0 && (
                    <div className="sponsors__section">
                        <h2 className="header__white">{componentText.titles.mediaPatronage}</h2>
                        <div>
                            {
                                SponsorsData.mediaPatronage.map((sponsor, index) => {
                                    return <a key={index} href={sponsor.url} target="_blank" rel="noreferrer">
                                        <img src={sponsor.logo} title={sponsor.name} alt={sponsor.name} />
                                    </a>
                                })
                            }
                        </div>
                    </div>
                )

            }

        </div>
    )
}

export default Sponsors